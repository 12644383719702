import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import bgButton from "../assets/contact-button.svg";
import Attachicon from "../assets/attachicon.svg";
import emailjs from "emailjs-com"; // Import EmailJS

const ContactForm = () => {
  const { t } = useTranslation();
  const formRef = useRef(); // Reference to the form element

  // State for the form fields
  const [interest, setInterest] = useState("Automation & Control System");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null); // File state
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false); // Loading state to show submit button status

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0]; // Get the first file
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name); // Update the file name state
    }
  };

  // Handle the change for "Area of Interest" dropdown
  const handleInterestChange = (event) => {
    setInterest(event.target.value);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    // Create a new FormData object to include all form fields and file
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("companyName", companyName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("message", message);
    if (file) {
      formData.append("file", file); // Append the file to formData
    }

    // Send the form data to EmailJS
    emailjs
      .sendForm(
        "service_f655c6t", // Replace with your EmailJS Service ID
        "template_7j94i7f", // Replace with your EmailJS Template ID
        formRef.current, // Pass the form reference here as the third parameter
        "RXUguIo_YaMq_n6w4"  // Replace with your EmailJS Public Key
      )
      .then(
        (result) => {
          console.log("Email sent successfully!", result.text);
          setLoading(false);
          alert("Form submitted successfully!");
        },
        (error) => {
          console.error("Error sending email:", error.text);
          setLoading(false);
          alert("There was an error submitting the form.");
        }
      )
      .finally(() => {
        setLoading(false); // Reset loading state in case of errors
      });
  };

  return (
    <section className="form-container pt-[140px] px-[40px] xxl:px-[20px] xml:pt-[100px] lg:pt-[80px] pb-[140px] border-b border-1 border-[#D2D3D4] xml:pb-[100px] lg:pb-[80px]">
    <div className="max-w-[1370px] w-full mx-auto">
      <form ref={formRef} onSubmit={handleSubmit}>
        {/* Part 1: Your Area of Interest */}
        <section className="area-of-interest grid gap-[0px] pb-[100px] xl:pb-[80px] md:pb-[60px]">
          <h1 className="text-[54px] text-[#1D1D1F] tracking-[-1.2px] leading-[58px] pb-[60px] xxl:text-[48px] xxl:tracking-[-1.2px] xxl:leading-[54px] xl:text-[42px] xl:tracking-[-1px] xl:leading-[45px] lg:text-[36px] lg:tracking-[-0.6px] lg:leading-[42px] lg:pb-[42px] md:pb-[33px] md:text-[36px] md:tracking-[-1px] md:leading-[39px]">
            {t("areaOfInterest")}
          </h1>
          <select
            name="interest"
            value={interest}
            onChange={handleInterestChange}
            className="select-field bg-transparent text-[16px] tracking-[-0.4px] h-[48px] w-full border-b-[2px] border-black outline-none msm:border-b-[1px]"
          >
            <option value="Automation & Control System">{t("automationControl")}</option>
            <option value="Software Engineering">{t("softwareEngineering")}</option>
            <option value="Data Science">{t("dataScience")}</option>
            <option value="Mechanical Engineering">{t("mechanicalEngineering")}</option>
          </select>
        </section>

        {/* Part 2: Contact Form */}
        <section className="contact-form">
          <h1 className="text-[54px] text-[#1D1D1F] tracking-[-1.2px] leading-[58px] pb-[60px] xxl:text-[48px] xxl:tracking-[-1.2px] xxl:leading-[54px] xl:text-[42px] xl:tracking-[-1px] xl:leading-[45px] lg:text-[36px] lg:tracking-[-0.6px] lg:leading-[42px] lg:pb-[42px] md:pb-[33px]">
            {t("contactForm")}
          </h1>

          <div className="grid gap-[80px]">
            <div className="grid grid-cols-2 gap-[16px] gap-y-[80px] md:grid-cols-1">
              <label className="text-[24px] text-[#1D1D1F] font-normal tracking-[-1px] xxl:text-[21px] xxl:tracking-[-0.7px]">
                {t("firstName")}
                <input
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  className="bg-transparent text-[16px] tracking-[-0.4px] h-[48px] w-full border-b-[2px] border-black outline-none msm:border-b-[1px]"
                />
              </label>
              <label className="text-[24px] text-[#1D1D1F] font-normal tracking-[-1px] xxl:text-[21px] xxl:tracking-[-0.7px]">
                {t("lastName")}
                <input
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  className="bg-transparent text-[16px] tracking-[-0.4px] h-[48px] w-full border-b-[2px] border-black outline-none msm:border-b-[1px]"
                />
              </label>
              <label className="text-[24px] text-[#1D1D1F] font-normal tracking-[-1px] xxl:text-[21px] xxl:tracking-[-0.7px]">
                {t("companyName")}
                <input
                  type="text"
                  name="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                  className="bg-transparent text-[16px] tracking-[-0.4px] h-[48px] w-full border-b-[2px] border-black outline-none msm:border-b-[1px]"
                />
              </label>
              <label className="text-[24px] text-[#1D1D1F] font-normal tracking-[-1px] xxl:text-[21px] xxl:tracking-[-0.7px]">
                {t("email")}
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="bg-transparent text-[16px] tracking-[-0.4px] h-[48px] w-full border-b-[2px] border-black outline-none msm:border-b-[1px]"
                />
              </label>
            </div>
            <label className="text-[24px] text-[#1D1D1F] font-normal tracking-[-1px] xxl:text-[21px] xxl:tracking-[-0.7px]">
              {t("phoneNumber")}
              <input
                type="tel"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                className="bg-transparent text-[16px] tracking-[-0.4px] h-[48px] w-full border-b-[2px] border-black outline-none msm:border-b-[1px]"
              />
            </label>
            <label className="text-[24px] text-[#1D1D1F] font-normal tracking-[-1px] xxl:text-[21px] xxl:tracking-[-0.7px]">
              {t("message")}
              <textarea
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                className="bg-transparent text-[16px] tracking-[-0.4px] h-[48px] w-full border-b-[2px] border-black outline-none msm:border-b-[1px]"
              />
            </label>
          </div>

          <div className="flex justify-between w-full items-center pt-[60px] md:grid md:gap-[60px] md:justify-center md:justify-items-center">
            {/* File Upload Custom Button */}
            <div className="grid gap-[24px] md:items-center md:justify-center md:justify-items-center">
              <div className="file-upload relative text-[#1D1D1F] text-[16px] font-semibold tracking-[-0.4px] leading-[14px] bg-white flex gap-[7px] items-center rounded-[9px] shadow-md border-2 border-[#E5E5E5] cursor-pointer w-fit">
                <input
                  type="file"
                  name="file"
                  id="file-upload"
                  onChange={handleFileUpload}
                  className="hidden"
                />
                <label
                  htmlFor="file-upload"
                  className="h-[48px] px-[24px] flex gap-[7px] items-center cursor-pointer"
                >
                  {t("fileUploadTwo")}
                  <img src={Attachicon} alt="icon" className="" />
                </label>
              </div>

              {/* Show the uploaded file name */}
              {fileName && (
                <p className="text-[18px] leading-[24px] tracking-[-0.6px] text-[#3D414E] font-medium md:text-center">
                  {t("attachedFile")}: {fileName}
                </p>
              )}

              <p className="text-[12px] leading-[16px] font-[500]">{t("formic")}</p>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="relative w-[169px] h-[54px] grid items-center justify-center transition-all hover:translate-y-[-6px] px-4"
              disabled={loading} // Disable button during loading state
            >
              <span className="text-white text-[16px] font-semibold text-center tracking-[-0.4px] leading-[16px] relative z-10">
                {loading ? t("loading") : t("submitButton")}
              </span>
              <img
                src={bgButton}
                alt="Button Background"
                className="absolute top-0 left-0 block w-full h-auto shadow-md rounded-[10px]"
              />
            </button>

            
          </div>
        </section>
      </form>
    </div>
  </section>
  );
};

export default ContactForm;
